import { render, staticRenderFns } from "./InceptionAssessmentEnglishWrapper.vue?vue&type=template&id=4e934584&scoped=true&"
import script from "./InceptionAssessmentEnglishWrapper.vue?vue&type=script&lang=ts&"
export * from "./InceptionAssessmentEnglishWrapper.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e934584",
  null
  
)

export default component.exports